import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import ReactPlayer from 'react-player'
import YouTube from 'react-youtube';
import HomePlayer from '../../videoPlayers/HomePlayer'
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 80,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 0
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%'
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  image: {
    //perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    //perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      //transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      //boxShadow: theme.shadows[0]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    }

  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
      <Grid
          container
          spacing={3}
          style={{paddingBottom: 15}}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h2"
                color="secondary"
                style={{
                  fontWeight: 'lighter',
                  paddingBottom: 10
                }}
              >
                Traks
              </Typography>
              <Box mt={1}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                >
                  Giving You back Your Time
                </Typography>
              </Box>
              <Box mt={1}>
              <Typography
                variant="h1"
                color="textPrimary"
                style={{
                  fontSize: 35,
                  marginBottom: 5
                }}
              >
                Fewer Angry Calls
              </Typography>
              <Typography
                variant="h1"
                color="textPrimary"
                style={{
                  fontSize: 35,
                  marginBottom: 5
                }}
              >
                Happier Customers
              </Typography>
              </Box>
              <Typography
                variant="h1"
                color="textPrimary"
                style={{
                  fontSize: 35,
                  marginBottom: 5
                }}
              >
                Your Day, Your Terms
              </Typography>
              <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      2 hrs+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Back Each Day
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      80% Fewer
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Angry Customer Calls
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      25% Better
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Web Traffic
                    </Typography>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Grid container
                  spacing={3}
                  >
                    <Grid item>
                      <Button
                        component={RouterLink}
                        to='/register'
                        variant="contained"
                        color="primary"
                        size="large"
                      >Sign Up Free</Button>
                    </Grid>
                    
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            
                <HomePlayer />
            
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;


//<Grid item>
//                      <Button
//                        color="primary"
//                        onClick={() => window.open('https://tracking.traksos.com/tracking/8e00f021-55b5-4ead-8ad3-de5aa0b4e810')}
//                        variant="outlined"
//                      >
//                        Example Tracker
//                    </Button>
//                    </Grid>