import React, {
  useEffect,
  useState,
  useCallback
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { 
  makeStyles,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import TopBar from './TopBar';
import AppIcon from 'src/components/AppIcon';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  logo: {
    marginRight: theme.spacing(2)
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [appModal, setModal] = useState(false);
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isMountedRef = useIsMountedRef();

  const openMobileLink = useCallback(() => {
    if (isMountedRef.current) {
      let isOnMobile = mobileDevice;

      if (isOnMobile) {
        setModal(true);

      } else {
        setModal(false);

      }
    }
  }, [isMountedRef])

  const handleClose = useCallback(() => {
    if (isMountedRef.current) {
      setModal(false);
    }
  }, [isMountedRef])

  useEffect(() => {
    openMobileLink();
  }, [openMobileLink]);

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
