import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Features = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          Explore Traks Tracking
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Not Just Another Business App
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  01
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Improve your Digital Footprint
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    We&apos;ve included the ability to quickly link to your 
                    Facebook, Instagram, Twitter, or even your company webpage in the 
                    Job Tracker Header. Drive traffic where you want 
                    to improve your digital footprint.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  02
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Streamline Customer Service
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    With Traks's innovative "Job Tracking" technology, clients can 
                    get realtime updates on the status of their project. They can easily see 
                    when they need to make payments and where their project is. No more "Checking In" phone calls.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  03
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Smart Presets
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Spend no time creating "Templates" for your job trackers. After making your 
                    first Project Tracker, you will be asked if you want this to be a preset. As you send more 
                    you get a larger library of presets. The time 
                    needed to complete your project Trackers only goes down.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
