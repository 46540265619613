import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  Grid,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#121118',
    paddingTop: 110,
    paddingBottom: 110
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const Steps = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
        <Typography
          variant="h1"
          align="left"
          style={{
            color: 'white',
            fontSize: 42,
            fontWeight: 'bold',
            marginBottom: 55
          }}
          className={classes.title}
        >
          Get started today
        </Typography>
        <Grid
          container
          spacing={5}
        >
          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Free Business App"
              src="/static/images/homeImages/applyImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 23 }}>
              1: Register Online
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Click the "Register Now" button, create an account, register your business, and start sending links now!
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Small Business Software Free"
              src="/static/images/homeImages/growImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 23 }}>
              2: Ready, Set, Grow
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              After Registering, you can import an existing Customer .csv file or add your current projects manually and begin 
              sending tracking links.
            </Typography>
          </Grid>


          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Automatic Reports and More"
              src="/static/images/homeImages/generateImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 23 }}>
              3: Power to the Click
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Powerful Technology at the click of a button. Generate your trackers as little as two clicks, and instantly email them 
              from the webpage.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Improve Small Business Profit"
              src="/static/images/homeImages/toolsImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 23 }}>
              4: More Time, More Money
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Get Realtime updates on which trackers are being viewed, and quiet the ringing phone to leave time for growing your business.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Customer Support and Customer Service App"
              src="/static/images/homeImages/upgradeImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 23 }}>
              5: Easy Support
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Support is easy with a phone call or email. We want to do everything we can to make our business better.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
                justifyItems: 'center',
                justify: 'space-between',
                alignContent: 'center',
                alignItems: 'center'
            }}
          >
            <Typography
              variant="h3"
              style={{
                color: 'white',
                marginTop: 15,
                fontSize: 28
              }}>
              Join the entrepreneurs who are building stronger businesses and delivering next-level results to their clients.
            </Typography>
            <Button
              size="large"
              component={RouterLink}
              to='/register'
              color="primary"
              variant="outlined"
              style={{
                  marginTop: 10
              }}
              endIcon={<ArrowForward />}
            >Register Now</Button>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
};

Steps.propTypes = {
  className: PropTypes.string
};

export default Steps;
