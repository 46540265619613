import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import InviteView from 'src/views/home/InviteView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import OnboardingGuard from 'src/components/OnboardingGuard';
import CompanyGuard from './components/CompanyGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: '/demo',
    component: lazy(() => import('src/views/demo'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/onboard_register',
    component: lazy(() => import('src/views/home/InviteView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/customer_recommend/:customerId',
    component: lazy(() => import('src/views/customerRecommend'))
  },
  {
    exact: true,
    guard: OnboardingGuard,
    path: '/onboarding',
    component: lazy(() => import('src/views/onboarding'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/invite',
    component: InviteView
  },
  {
    exact: true,
    path: '/tracking/:trackingId',
    component: lazy(() => import('src/views/tracking'))
  },
  {
    exact: true,
    path: '/review/:reviewId',
    component: lazy(() => import('src/views/reviews'))
  },
  {
    exact: true,
    path: "/previewLink/:trackingId",
    component: lazy(() => import('src/views/previewTracker'))
  },
  {
    exact: true,
    path: "/recommend",
    component: lazy(() => import('src/views/recommend'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/employee/:empId',
    component: lazy(() => import('src/views/employeeSignUp'))
  },
  {
    exact: true,
    path: '/app/checkout_yearly',
    guard: AuthGuard,
    component: lazy(() => import('src/views/checkout/regularCheckout/yearly'))
  },
  {
    exact: true,
    path: '/app/checkout_attach_monthly',
    guard: AuthGuard,
    component: lazy(() => import('src/views/checkout/attachCheckout/monthly'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/app/checkout_monthly',
    component: lazy(() => import('src/views/checkout/regularCheckout/monthly'))
  },
  {
    exact: true,
    path: '/app/checkout_attach_yearly',
    guard: AuthGuard,
    component: lazy(() => import('src/views/checkout/attachCheckout/yearly'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/company/add_employee',
        component: lazy(() => import('src/views/company/AddEmployee'))
      },
      {
        exact: true,
        path: '/app/referral_program',
        component: lazy(() => import('src/views/addPromo'))
      },
      {
        exact: true,
        path: `/app/company`,
        component: lazy(() => import('src/views/company/CompanyView'))
      },
      {
        exact: true,
        path: `/app/company/manageEmployees`,
        component: lazy(() => import('src/views/network/ManageEmployees'))
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/louveroofDealer',
        component: lazy(() => import('src/views/louveRoof'))
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/clientImport',
        component: lazy(() => import('src/views/customer/ClientImport'))
      },
      {
        exact: true,
        path: '/app/management/clientArchive',
        component: lazy(() => import ('src/views/customer/ClientArchiveView'))
      },
      {
        exact: true,
        path: '/app/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/trackers',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/jobArchive',
        component: lazy(() => import ('src/views/product/JobArchiveView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/trackers" />
      },
      {
        exact: true,
        path: '/app/trackers/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/trackers',
        component: () => <Redirect to="/app/trackers/browse" />
      },
      {
        exact: true,
        path: '/app/how-to',
        component: lazy(() => import('src/views/howTo'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/management/trackers" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        //guard: GuestGuard,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
