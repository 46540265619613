/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Grid,
  Typography
} from '@material-ui/core';
import {
  Link as LinkIcon,
  BarChart as BarChartIcon,
  User as UserIcon,
  Clipboard as Clipboard,
  Users as UsersIcon,
  Sun as SunIcon,
  UserPlus as UserPlusIcon,
  PlusCircle as PlusIcon,
  DollarSign as Dollar,
  Youtube as Youtube,
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

const differentSections = [
  {
    subheader: 'Analytics',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/dashboard'
      }
    ],
  },{
    subheader: 'Management',
    items: [
      {
        title: 'Trackers',
        icon: LinkIcon,
        href: '/app/management/trackers',
        items: [
          {
            title: 'Open Tracker List',
            href: '/app/management/trackers'
          },
          {
            title: 'Tracker Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Import CSV',
            href: '/app/management/clientImport'
          },
          {
            title: 'Client Archive',
            href: '/app/management/clientArchive'
          }
        ]
      },
    ]
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Add Company',
        href: '/app/add_company',
        icon: Clipboard
      }
    ]
  }
  
]

const sections = [
  {
    subheader: 'Analytics',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/dashboard'
      }
    ],
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Trackers',
        icon: LinkIcon,
        href: '/app/management/trackers',
        items: [
          {
            title: 'Open Tracker List',
            href: '/app/management/trackers'
          },
          {
            title: 'Tracker Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Import CSV',
            href: '/app/management/clientImport'
          },
          {
            title: 'Client Archive',
            href: '/app/management/clientArchive'
          }
        ]
      },
    ]
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Add Employees',
        href: '/app/company/add_employee',
        icon: PlusIcon,
      },
      {
        title: 'Manage Employees',
        href: '/app/company/companyNetwork',
        icon: UserPlusIcon,
        href: '/app/company/ManageEmployees'
      },
//      {
//        title: 'My Network',
//        href: "/app/myNetwork",
//        icon: UserPlusIcon,
//        items: [
//          {
//            title: 'My Network',
//            href: '/app/myNetwork'
//          },
//          {
//            title: 'Add To Network',
//            href: '/app/addToNetwork'
//          }
//        ]
//      }
    ]
  },

//  {
//    subheader: 'Applications',
//    items: [
//      
//      {
//        title: 'Networking',
  //        href: '/app/social',
  //        icon: ShareIcon,
  //        items: [
  //          {
  //            title: 'Profile',
  //            href: '/app/social/profile'
  //          },
  //          {
  //            title: 'Feed',
  //            href: '/app/social/feed'
  //          }
  //        ]
  //      }
  //    ]
  //  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Company',
        href: '/app/company',
        icon: Clipboard
      }
    ]
  }
];

const LouveRoofSections = [
  {
    
      subheader: 'Analytics',
      items: [
        {
          title: 'Dashboard',
          icon: BarChartIcon,
          href: '/app/dashboard'
        }
      ],
    },{
    subheader: 'Management',
    items: [
      {
        title: 'Trackers',
        icon: LinkIcon,
        href: '/app/management/trackers',
        items: [
          {
            title: 'Open Tracker List',
            href: '/app/management/trackers'
          },
          {
            title: 'Tracker Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Import CSV',
            href: '/app/management/clientImport'
          },
          {
            title: 'Client Archive',
            href: '/app/management/clientArchive'
          }
        ]
      },
    ]
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Add Employees',
        href: '/app/company/add_employee',
        icon: PlusIcon,
      },
      {
        title: 'Manage Employees',
        href: '/app/company/companyNetwork',
        icon: UserPlusIcon,
        href: '/app/company/ManageEmployees'
      },
//      {
//        title: 'My Network',
//        href: "/app/myNetwork",
//        icon: UserPlusIcon,
//        items: [
//          {
//            title: 'My Network',
//            href: '/app/myNetwork'
//          },
//          {
//            title: 'Add To Network',
//            href: '/app/addToNetwork'
//          }
//        ]
//      }
    ]
  },

//  {
//    subheader: 'Applications',
//    items: [
//      
//      {
//        title: 'Networking',
  //        href: '/app/social',
  //        icon: ShareIcon,
  //        items: [
  //          {
  //            title: 'Profile',
  //            href: '/app/social/profile'
  //          },
  //          {
  //            title: 'Feed',
  //            href: '/app/social/feed'
  //          }
  //        ]
  //      }
  //    ]
  //  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Company',
        href: '/app/company',
        icon: Clipboard
      },
      {
        title: 'LouveRoof',
        href: '/app/louveroofDealer',
        icon: SunIcon
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  bottomPush: {
    position: 'fixed',
    bottom: 0,
    paddingLeft: '10px',
    textAlign: 'center',
    paddingBottom: 10,
    alignContent: 'center',
    justifyContent: 'center'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  const bottomRender = () => {
    const didPay = user.userPaid;
    const endDate = moment(user.userSubscriptionEnd);
    const difference = moment() - endDate
    if (didPay) {
      return 'Subscription Active'
    } else if (difference < 0) {
      return `Activate Pro`
    } else if (difference > 0) {
      return `Activate Pro`
    }
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const isPaid = user.userPaid ? true : false;
  const isCompany = user.owner !== user.companyUuid

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
          </Box>
          <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          style={{
            marginTop: 10
          }}
          >
            <Link
            component={RouterLink}
            to={user.userPaid ? '/app/account' : '/pricing'}
            variant="h5"
            color="primary"
            underline="always"
            >
              {bottomRender()}
            </Link>
          </Box>
          {isPaid && (
            <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            style={{
              marginTop:10
            }}
            >
              <Button
              component={RouterLink}
              to='/app/referral_program'
              variant="contained"
              startIcon={<Dollar />}
              color="primary"
              >Referral Program</Button>
              </Box>
          )}
        </Box>
        <Divider />
        <Box p={2}>
          {user.owner !== user.companyUuid ? (
            <div>
              {differentSections.map((section) => (
                <List
                  key={section.subheader}
                  subheader={(
                    <ListSubheader
                      disableGutters
                      disableSticky
                    >
                      {section.subheader}
                    </ListSubheader>
                  )}
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname
                  })}
                </List>
              ))}
            </div>
          ) : user.louveRoofDealer ? (
            <div>
              {LouveRoofSections.map((section) => (
                <List
                  key={section.subheader}
                  subheader={(
                    <ListSubheader
                      disableGutters
                      disableSticky
                    >
                      {section.subheader}
                    </ListSubheader>
                  )}
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname
                  })}
                </List>
              ))}
            </div>
          ) :
              (
                <div>
                  {sections.map((section) => (
                    <List
                      key={section.subheader}
                      subheader={(
                        <ListSubheader
                          disableGutters
                          disableSticky
                        >
                        {section.subheader}
                      </ListSubheader>
                    )}
                  >
                    {renderNavItems({
                      items: section.items,
                      pathname: location.pathname
                    })}
                  </List>
                ))}
              </div>
            )}
            

        </Box>
        <Divider />

      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}

          {!user.userPaid ? (
            <Box display="flex" flexDirection="row" className={classes.bottomPush}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                component="a"
                href="/pricing"
                variant="contained"
              >
                Activate Pro
              </Button>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" className={classes.bottomPush}>
                
                  <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={() => window.open('https://pro.traksos.com')}
                >Access Pro Suite</Button>
                </Box>
              )}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
          {!user.userPaid ? (
            <Box display="flex" flexDirection="row" className={classes.bottomPush}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                component="a"
                href="/pricing"
                variant="contained"
              >
                Activate Pro
              </Button>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" className={classes.bottomPush}>
                
                  <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={() => window.open('https://pro.traksos.com')}
                >Access Pro Suite</Button>

                  
                </Box>
              )}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

//        <Box p={2}>
//<Box
//p={2}
//borderRadius="borderRadius"
//bgcolor="background.dark"
//>
//<Typography
//  variant="h6"
//  color="textPrimary"
//>
//  Need Help?
//</Typography>
//<Link
//  variant="subtitle1"
//  color="secondary"
//  component={RouterLink}
//  to="/docs"
//>
//  Check our Tutorials
//</Link>
//</Box>
//</Box>


//<Button
//fullWidth
//color="primary"
//variant="contained"
//onClick={() => window.open('https://pro.traksos.com')}
//>Access Pro Suite</Button>
//) : (
