import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase, { db, analytics} from 'src/lib/firebase';
import axios from 'axios'
import moment from 'moment';

const initialAuthState = {
  isAuthenticated: false,
  isOnboarded: false,
  isInitialised: false,
  user: null,
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, isOnboarded, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isOnboarded,
        isInitialised: true,
        loading: false,
        user
      };
    }
    case 'SET_LOADING': {
      const { loading } = action.payload;

      return {
        ...state,
        loading
      }
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInEmpWithEmailAndPassword: () => Promise.resolve(),
  signInWithPhone: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  signInWithApple: () => Promise.resolve(),
  resendVerification: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithPhone = (phoneNum, verifier) => {

    return firebase.auth().signInWithPhoneNumber(phoneNum, verifier)
  }

  const signInWithEmailAndPassword = (email, password) => {
    firebase.auth().signOut();
    return firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
      analytics.logEvent('login');
    });
  };

  const signInEmpWithEmailAndPassword = (email, password, company) => {
    firebase.auth().signOut();
    let payload = {
      email: email
    }
    axios.defaults.baseURL = 'https://us-central1-see-hi.cloudfunctions.net/api';
    axios.post(`/registerEmployee`, payload).then((res) => {
      console.log(res.data);
      return firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
        analytics.logEvent('empLogin')
      })
    })
      .catch((err) => {
        console.err(JSON.stringify(err));
        alert(JSON.stringify(err));
      })
  }

  const signInWithGoogle = () => {
    firebase.auth().signOut();
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const signInWithFacebook = () => {
    firebase.auth().signOut();
    const provider = new firebase.auth.FacebookAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  }

  const signInWithApple = () => {
    firebase.auth().signOut();
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return firebase.auth().signInWithPopup(provider)
  }

  const createUserWithEmailAndPassword = async (email, password) => {
    analytics.logEvent('sign_up');
    return firebase.auth().createUserWithEmailAndPassword(email, password).then((cred => {

      const user = firebase.auth().currentUser;
      return user.sendEmailVerification();
    }))
  };

  const resetPassword = async (email) => {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  const resendVerification = async () => {
    const user = firebase.auth().currentUser;
    return user.sendEmailVerification();
  }

  const changePassword = async (password, newPassword) => {
    const user = firebase.auth().currentUser;
    return user.updatePassword(newPassword);
  }

  const logout = () => {
    return firebase.auth().signOut();
  };

  const updateUser = async (uuid) => {
    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading: true
      }
    });
    const user = await db.doc(`/MUser/${uuid}`).get();
    if (user.exists) {
      const doc = user.data();
      const companyUser = doc.companyUuid !== '' ? true : false;
      const companyDoc = companyUser ? await db.doc(`/Company/${doc.companyUuid}`).get() : null;
      analytics.setUserId(doc.uuid);
      analytics.setUserProperties({
        city: doc.city,
        companyID: doc.companyUuid,
        companyName: doc.company,
        email: doc.email,
        uuid: doc.uuid,
        state: doc.state,
      });


      if (companyDoc === null || !companyDoc.exists) {
        dispatch({
          type: `AUTH_STATE_CHANGED`,
          payload: {
            isAuthenticated: true,
            isOnboarded: doc.onBoarded,
            user: {
              id: doc.uuid,
              uuid: doc.uuid,
              avatar: doc.userProfileImageLink,
              email: doc.email,
              name: doc.username || doc.email,
              firstName: doc.userFirstName || '',
              lastName: doc.userLastName || '',
              streetAddress: doc.streetAddress,
              city: doc.city,
              state: doc.state,
              zipcode: doc.zipcode,
              phone: doc.phone,
              userPaid: doc.userPaid,
              userSubscriptionStart: doc.userSubscriptionStart,
              userSubscriptionEnd: doc.userSubscriptionEnd,
              userSubStart: moment(doc.userSubscriptionStart).toDate(),
              userSubEnd: moment(doc.userSubscriptionEnd).toDate(),
              onBoarded: doc.onBoarded,
              company: doc.company,
              companyUser: false,
              companyUuid: doc.companyUuid,
              permissionLevel: doc.permissionLevel,
              tier: doc.plan !== undefined ? doc.plan : 'Free',
              owner: doc.companyUuid === '' ? doc.uuid : doc.companyUuid,
              userNetwork: doc.userNetwork ? doc.userNetwork : [],
              jobRole: doc.jobRole || '',
              isAdmin: true,
              hasAttach: false
            }
          }
        })
      } else {
        const company = companyDoc.data();
        const louveRoofDealer = company.louveRoofDealer === true
        const isAdmin = company.administrator.indexOf(doc.uuid) > -1 ? true : false;

        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            isOnboarded: doc.onBoarded,
            user: {
              id: doc.uuid,
              uuid: doc.uuid,
              avatar: doc.userProfileImageLink,
              email: doc.email,
              name: doc.username || doc.email,
              firstName: doc.userFirstName || '',
              lastName: doc.userLastName || '',
              streetAddress: doc.streetAddress,
              city: doc.city,
              state: doc.state,
              zipcode: doc.zipcode,
              phone: doc.phone,
              userPaid: doc.userPaid,
              userSubscriptionStart: doc.userSubscriptionStart,
              userSubscriptionEnd: doc.userSubscriptionEnd,
              userSubStart: moment(doc.userSubscriptionStart).toDate(),
              userSubEnd: moment(doc.userSubscriptionEnd).toDate(),
              onBoarded: doc.onBoarded,
              company: doc.company,
              companyUser: true,
              companyUuid: doc.companyUuid,
              permissionLevel: doc.permissionLevel,
              tier: doc.plan !== undefined ? louveRoofDealer === true ? 'Business Optimize' : doc.plan : 'Free',
              owner: doc.companyUuid === '' ? doc.uuid : doc.companyUuid,
              userNetwork: doc.userNetwork ? doc.userNetwork : [],
              jobRole: doc.jobRole || '',
              companyInfo: company,
              isAdmin: isAdmin,
              louveRoofDealer: louveRoofDealer,
              hasAttach: company.isAttachment ? company.isAttachment : false
            }
          }
        })
      }
    }
  }

  useEffect(() => {

    const unsubscribe = firebase.auth().onAuthStateChanged((thisUser) => {

      dispatch({
        type: 'SET_LOADING',
        payload: {
          loading: true
        }
      });

      const newUser = thisUser;
      const providerOfAuth = newUser !== null ? newUser.providerData[0].providerId : ''
      const isVerified = newUser ? newUser.emailVerified : false

      const canContinue = isVerified || providerOfAuth === 'facebook.com'


      if (newUser !== null && canContinue) {

        return db.doc(`/MUser/${thisUser.uid}`).get().then((user) => {


          if (user.exists) {
            const doc = user.data();
            const companyUser = doc.companyUuid !== '' ? true : false;
            
            if (companyUser) {
              return db.doc(`/Company/${doc.companyUuid}`).get().then((companyDoc) => {

                if (companyDoc.exists) {
                  const company = companyDoc.data();
                  const isAdmin = company.administrator.indexOf(doc.uuid) > -1 ? true : false;
                  const louveRoof = company.louveRoofDealer !== undefined ? company.louveRoofDealer : false;

                  analytics.setUserId(doc.uuid);
                  analytics.setUserProperties({
                    city: doc.city,
                    companyID: doc.companyUuid,
                    companyName: doc.company,
                    email: doc.email,
                    uuid: doc.uuid,
                    state: doc.state,
                  })

                  dispatch({
                    type: `AUTH_STATE_CHANGED`,
                    payload: {
                      isAuthenticated: true,
                      isOnboarded: doc.onBoarded,
                      user: {
                        id: doc.uuid,
                        uuid: doc.uuid,
                        avatar: doc.userProfileImageLink,
                        email: doc.email,
                        name: doc.username || doc.email,
                        firstName: doc.userFirstName || '',
                        lastName: doc.userLastName || '',
                        streetAddress: doc.streetAddress,
                        city: doc.city,
                        state: doc.state,
                        zipcode: doc.zipcode,
                        phone: doc.phone,
                        userPaid: doc.userPaid,
                        userSubscriptionStart: doc.userSubscriptionStart,
                        userSubscriptionEnd: doc.userSubscriptionEnd,
                        userSubStart: moment(doc.userSubscriptionStart).toDate(),
                        userSubEnd: moment(doc.userSubscriptionEnd).toDate(),
                        onBoarded: doc.onBoarded,
                        company: doc.company,
                        companyUser: companyUser,
                        companyUuid: doc.companyUuid,
                        permissionLevel: doc.permissionLevel,
                        tier: doc.plan !== undefined ? louveRoof === true ? 'Business Optimize' : doc.plan : 'Free',
                        owner: doc.companyUuid === '' ? doc.uuid : doc.companyUuid,
                        userNetwork: doc.userNetwork ? doc.userNetwork : [],
                        jobRole: doc.jobRole || '',
                        companyInfo: company,
                        isAdmin: isAdmin,
                        louveRoofDealer: louveRoof,
                        hasAttach: company.isAttachment ? company.isAttachment : false,
                        webTute: doc.webTute ? doc.webTute : false
                      }
                    }
                  })

                } else {

                  analytics.setUserId(doc.uuid);
                  analytics.setUserProperties({
                    city: doc.city,
                    companyID: doc.companyUuid,
                    companyName: doc.company,
                    email: doc.email,
                    uuid: doc.uuid,
                    state: doc.state,
                  })

                  dispatch({
                    type: `AUTH_STATE_CHANGED`,
                    payload: {
                      isAuthenticated: true,
                      isOnboarded: doc.onBoarded,
                      user: {
                        id: doc.uuid,
                        uuid: doc.uuid,
                        avatar: doc.userProfileImageLink,
                        email: doc.email,
                        name: doc.username || doc.email,
                        firstName: doc.userFirstName || '',
                        lastName: doc.userLastName || '',
                        streetAddress: doc.streetAddress,
                        city: doc.city,
                        state: doc.state,
                        zipcode: doc.zipcode,
                        phone: doc.phone,
                        userPaid: doc.userPaid,
                        userSubscriptionStart: doc.userSubscriptionStart,
                        userSubscriptionEnd: doc.userSubscriptionEnd,
                        userSubStart: moment(doc.userSubscriptionStart).toDate(),
                        userSubEnd: moment(doc.userSubscriptionEnd).toDate(),
                        onBoarded: doc.onBoarded,
                        company: doc.company,
                        companyUser: companyUser,
                        companyUuid: doc.companyUuid,
                        permissionLevel: doc.permissionLevel,
                        tier: doc.plan !== undefined ? doc.plan : 'Free',
                        owner: doc.companyUuid === '' ? doc.uuid : doc.companyUuid,
                        userNetwork: doc.userNetwork ? doc.userNetwork : [],
                        jobRole: doc.jobRole || '',
                        isAdmin: true,
                        hasAttach: false,
                        webTute: doc.webTute ? doc.webTute : false
                      }
                    }
                  })
                }
              })
            } 
            else {
              analytics.setUserId(doc.uuid);
              analytics.setUserProperties({
                city: doc.city,
                companyID: doc.companyUuid,
                companyName: doc.company,
                email: doc.email,
                uuid: doc.uuid,
                state: doc.state,
              })

              dispatch({
                type: `AUTH_STATE_CHANGED`,
                payload: {
                  isAuthenticated: true,
                  isOnboarded: doc.onBoarded,
                  user: {
                    id: doc.uuid,
                    uuid: doc.uuid,
                    avatar: doc.userProfileImageLink,
                    email: doc.email,
                    name: doc.username || doc.email,
                    firstName: doc.userFirstName || '',
                    lastName: doc.userLastName || '',
                    streetAddress: doc.streetAddress,
                    city: doc.city,
                    state: doc.state,
                    zipcode: doc.zipcode,
                    phone: doc.phone,
                    userPaid: doc.userPaid,
                    userSubscriptionStart: doc.userSubscriptionStart,
                    userSubscriptionEnd: doc.userSubscriptionEnd,
                    userSubStart: moment(doc.userSubscriptionStart).toDate(),
                    userSubEnd: moment(doc.userSubscriptionEnd).toDate(),
                    onBoarded: doc.onBoarded,
                    company: doc.company,
                    companyUser: companyUser,
                    companyUuid: doc.companyUuid,
                    permissionLevel: doc.permissionLevel,
                    tier: doc.plan !== undefined ? doc.plan : 'Free',
                    owner: doc.companyUuid === '' ? doc.uuid : doc.companyUuid,
                    userNetwork: doc.userNetwork ? doc.userNetwork : [],
                    jobRole: doc.jobRole || '',
                    isAdmin: true,
                    hasAttach: false,
                    webTute: doc.webTute ? doc.webTute : false,
                  }
                }
              })
            }
          } else {

            const uuid = newUser.uid;

            const now = moment().format('MM-DD-YY HH:mm');
            //const end = moment().add(60, 'd').format('MM-DD-YY HH:mm')
            //console.log(end);

            let addUser = {
              city: '',
              company: '',
              companyUuid: '',
              email: newUser.email,
              jobRole: '',
              onBoarded: false,
              permissionLevel: [],
              phone: '',
              state: '',
              streetAddress: '',
              token: [],
              userApproveCompanyAdd: false,
              userCompanyAddRequested: false,
              userFirstName: '',
              userLastName: '',
              userNetwork: [],
              userPaid: false,
              userProfileImageLink: '',
              userSubscriptionEnd: now,
              userSubscriptionStart: now,
              username: '',
              uuid: uuid,
              zipcode: '',
              platform: 'Web',
              suiteUser: false,
              webTute: false,
              mobTute: false
            }

            db.doc(`/MUser/${addUser.uuid}`).set(addUser);
            
            analytics.setUserId(addUser.uuid);
            analytics.setUserProperties({
              city: addUser.city,
              companyID: addUser.companyUuid,
              companyName: addUser.company,
              email: addUser.email,
              uuid: addUser.uuid,
              state: addUser.state,
            })
            analytics.logEvent('first_login');

            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: true,
                isOnboarded: false,
                user: {
                  id: addUser.uuid,
                  uuid: addUser.uuid,
                  companyUser: false,
                  avatar: '',
                  email: addUser.email,
                  company: '',
                  name: '',
                  city: '',
                  state: '',
                  zipcode: '',
                  phone: '',
                  userPaid: false,
                  userSubscriptionStart: now,
                  userSubscriptionEnd: now,
                  userSubStart: moment(now).toDate(),
                  userSubEnd: moment(now).toDate(),
                  onBoarded: false,
                  company: '',
                  companyUuid: '',
                  permissionLevel: [],
                  userNetwork: [],
                  tier: 'Free',
                  owner: uuid,
                  isAdmin: true,
                  hasAttach: false,
                  webTute: false,
                  mobTute: false
                }
              }
            })
          }
        })
      } else {

        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            isOnboarded: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInEmpWithEmailAndPassword,
        signInWithPhone,
        signInWithGoogle,
        signInWithFacebook,
        signInWithApple,
        changePassword,
        updateUser,
        resendVerification,
        resetPassword,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
