import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

const CTA = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Ready to Get Your Time Back?
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="secondary"
        >
          Get Started for Free Today!
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container spacing={3} direction="row" justify="center" alignContent="center">
            
            <Grid item>
            <Button
            color="secondary"
            component="a"
            href="/register"
            variant="contained"
            size="large"
          >
            Get Started
          </Button>
            </Grid>
            <Grid item>
              <Button
              color="secondary"
              component="a"
              href="/demo"
              variant="outlined"
              size="large"
              >Schedule a Demo</Button>
              
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;


//<Button
//            color="primary"
//            onClick={() => window.open('https://tracking.traksos.com/tracking/8e00f021-55b5-4ead-8ad3-de5aa0b4e810')}
//            variant="outlined"
//          >
//            Demo Tracker
//          </Button>