import React from 'react'
import ReactPlayer from 'react-player'
import './responsive-player.css'

const HomePlayer = () => {
    return (
        <div className="player-wrapper" alt="Invoicing App">
          <ReactPlayer url='https://youtu.be/zDimP5Z0oYA'
          className="react-player"
              playing={true}
              loop={true}
              muted={true}
              width='100%'
              height='100%'
              />
        </div>
      );
}

export default HomePlayer